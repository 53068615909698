import React from "react";

const GlobalWaitlistRevision = ({ visits, appearance: { listItemColor } }) => {
  const getMaxIndexPerCategory = visits => {
    if (visits.length <= 3) {
      return 5;
    } else {
      return 2;
    }
  };
  return (
    <div className="global-waitlist-category-wrapper-revision">
      {visits &&
        Object.keys(visits).map(
          (visitCategory, index) =>
            visitCategory !== "Curbside" && (
              <div key={index} className="global-waitlist-category-wrapper">
                <div
                  style={{
                    color: listItemColor
                  }}
                  className="global-waitlist-category"
                >
                  {visitCategory}
                </div>
                {visits[visitCategory].map(
                  (visit, index) =>
                    index <= getMaxIndexPerCategory(visits) && (
                      <div
                        key={index}
                        className={`gloal-waitlist-list-unit-revision${
                          Object.keys(visits).length > 4 ? " compact" : ""
                        }`}
                      >
                        <div
                          style={{
                            color: listItemColor
                          }}
                          className="gloal-waitlist-list-unit-number"
                        >
                          {index + 1}
                        </div>
                        <div
                          style={{
                            color: listItemColor
                          }}
                          className="gloal-waitlist-list-unit-name"
                        >
                          {visit.name}
                        </div>
                      </div>
                    )
                )}
              </div>
            )
        )}
    </div>
  );
};

export default GlobalWaitlistRevision;
