import React from "react";
import { Modal, Button } from "react-bootstrap";

function EmailNoticeModal({ show, handleOk, email }) {
  console.log("debug", handleOk);
  return (
    <Modal className="static-modal" show={show}>
      <Modal.Header>
        <Modal.Title>IMPORTANT NOTICE!</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <span>
          Due to changing regulations, <strong style={{ color: "#000" }}>we are longer able to text you</strong> when
          you are next in line. We’ll email you at <strong style={{ color: "#000" }}>{email}</strong> Please check your
          email regularly for updates on your status
        </span>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="primary" onClick={handleOk}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EmailNoticeModal;
